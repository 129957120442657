<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, computed } from "vue";
import { useElementVisibility } from "@vueuse/core";
import clientConfig from "@/config/client";
import "bootstrap";
import CalculatorItem from "./CalculatorItem.vue";
import { useMoneyFitStore } from "@/store/index";
const store = useMoneyFitStore();
store.$insertClarityAnalyticsCode();

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const filterCalcs = ref("all");
const modellers = ref(clientConfig.content.modellers);

const moneyFit = ref(null);
const scrolledToMoneyFit = useElementVisibility(moneyFit);
const calculators = ref(null);
const scrolledToCalculators = useElementVisibility(calculators);
const contacts = ref(null);
const scrolledToContacts = useElementVisibility(contacts);

const emailSubject = ref("");
const emailMessage = ref("");
const emailTel = ref("");
const emailEmployer = ref("");
const emailSender = ref("");
const emailAddress = ref("");
const emailCopy = ref("");

const dynamicURL = computed(() => {
  let link = null;
  if (process.env.NODE_ENV === "production") {
    link = "/healthcheck/";
  } else if (process.env.NODE_ENV === "staging") {
    link = clientConfig.content.general.healthcheckDevLink;
  } else if (process.env.NODE_ENV === "development") {
    link = clientConfig.content.general.healthcheckDevLink;
  }
  return link;
});

function sendEmail(e) {
  emailCopy.value = `Employer: ${emailEmployer.value}%0D%0AMessage: ${emailMessage.value}%0D%0ATel: ${emailTel.value}%0D%0AName: ${emailSender.value}%0D%0AEmail Address:${emailAddress.value}`;
  if (
    emailSubject.value != "" &&
    emailMessage.value != "" &&
    emailTel.value != "" &&
    emailEmployer.value != "" &&
    emailSender.value != "" &&
    emailAddress.value != "" &&
    emailCopy.value != ""
  ) {
    window.open(
      `mailto:getmoneyfit@ajg.com.au?subject=${emailSubject.value}&body=${emailCopy.value}`
    );
    e.preventDefault();
  }
}
</script>

<template>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex justify-content-between p-0">
      <a href="/" class="logo mr-auto" aria-label="Homepage"></a>
      <nav class="navbar navbar-expand-lg navbar-light nav-menu">
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  to="/#moneyfit"
                  :class="scrolledToMoneyFit ? 'active' : ''"
                  >Financial Wellbeing</router-link
                >
              </li>
              <span class="spacer"> | </span>
              <li class="nav-item">
                <router-link
                  to="/#calculators"
                  :class="scrolledToCalculators ? 'active' : ''"
                  >Resources and Tools</router-link
                >
              </li>
              <span class="spacer"> | </span>
              <li class="nav-item">
                <router-link
                  to="/#contacts"
                  :class="scrolledToContacts ? 'active' : ''"
                  >Contact Us</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <!-- End Header -->

  <main id="main">
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="home" ref="home">
      <div class="overlay">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 order-2 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            ></div>
            <div
              class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-1 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>
                Welcome to the<br />
                {{ clientConfig.content.general.clientName }} Financial
                Wellbeing Hub
              </h1>
              <h2>
                {{ clientConfig.content.heroIntro }}
              </h2>
              <div class="d-lg-flex">
                <router-link to="/#calculators" class="btn-get-started scrollto"
                  >Find out more</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero -->
    <!-- ======= MoneyFit Section ======= -->
    <section id="moneyfit" class="moneyfit">
      <div
        class="container d-flex justify-content-between p-0"
        data-aos="fade-up"
      >
        <div class="col-lg-6 col-xl-5 py-4 px-5 moneyfit-description">
          <h2>MoneyFit Healthcheck</h2>
          <p>
            Financial Wellbeing is all about feeling confident and in control of
            your money. That's why, Gallagher is offering you MoneyFit, 100%
            anonymous and 100% secure.
          </p>
          <div class="d-flex justify-content-between">
            <a
              :href="`${dynamicURL}`"
              class="btn-get-started scrollto ml-0"
              target="_blank"
              >Visit MoneyFit</a
            >
            <a
              href="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fcalendly.com%2Ftchai_oliveira%2Fyour-moneyfit-consult&data=05%7C02%7CMarina_Brunello%40ajg.com.au%7Ce666f36015b14a33e8f908dc7bb4e064%7C6cacd170f8974b19ac5846a23307b80a%7C1%7C0%7C638521264123984163%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=pNNvi0x8K%2FyMF19oWslb9X0yRMhswOcyoBtJXFiFDBY%3D&reserved=0"
              class="btn-get-started scrollto"
              target="_blank"
              >Book a consultation</a
            >
          </div>
        </div>
        <img src="@/assets/img/ostrich.svg" class="overlapping-img" alt="" />
        <img src="@/assets/img/kangaroo.svg" class="overlapping-img" alt="" />
        <img src="@/assets/img/owl.svg" class="overlapping-img" alt="" />
        <div class="col-lg-4 col-xl-4 moneyfit-img"></div>
      </div>
    </section>
    <!-- End About Us Section -->

    <!-- ======= Calculators Section ======= -->
    <section id="calculators" ref="calculators" class="calculators section-bg">
      <div class="overlay">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-8">
              <h2>All Tools & Calculators</h2>

              <p>
                Financial wellbeing is about having the financial freedom to
                make choices that allow you to enjoy life. Below are some free
                tools that can help you achieve your financial dreams.
              </p>

              <div class="row" data-aos="fade-up" data-aos-delay="100">
                <div class="col-lg-12 d-flex justify-content-left gap-2">
                  <ul id="calcs-filters">
                    <li
                      :class="filterCalcs == 'all' ? 'filter-active' : ''"
                      @click="filterCalcs = 'all'"
                      tabindex="0"
                    >
                      <img src="@/assets/img/calcs.svg" alt="" class="mr-3" />
                      All tools
                    </li>
                    <li
                      :class="filterCalcs == 'budgeting' ? 'filter-active' : ''"
                      @click="filterCalcs = 'budgeting'"
                      tabindex="0"
                    >
                      <img src="@/assets/img/pie.svg" alt="" class="mr-3" />
                      Budgeting
                    </li>
                    <li
                      :class="filterCalcs == 'savings' ? 'filter-active' : ''"
                      @click="filterCalcs = 'savings'"
                      tabindex="0"
                    >
                      <img
                        src="@/assets/img/insurance.svg"
                        alt=""
                        class="mr-3"
                      />
                      Saving for the Future
                    </li>
                    <li
                      :class="filterCalcs == 'debt' ? 'filter-active' : ''"
                      @click="filterCalcs = 'debt'"
                      tabindex="0"
                    >
                      <img src="@/assets/img/debt.svg" alt="" class="mr-3" />
                      Managing Debts
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row calcs-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <CalculatorItem
              v-for="modeller in modellers"
              :key="modeller"
              :title="modeller.title"
              :description="modeller.description"
              :type="modeller.type"
              :url="modeller.url"
              :externalUrl="modeller.externalUrl"
              :categoryPic="modeller.categoryPic"
              :bgImage="modeller.bgImage"
              :heading="modeller.heading"
              :subheading="modeller.subheading"
              :link="modeller.link"
              :moneyFit="modeller.moneyFit"
              v-show="modeller.type == filterCalcs || filterCalcs == 'all'"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- End Calcs Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contacts" class="contact">
      <form
        class="container d-flex justify-content-between p-0"
        data-aos="fade-up"
        @submit="checkForm"
      >
        <div class="col-lg-4 pt-4 pl-5 pb-5 get-in-touch">
          <h3 class="mb-5">Get in touch</h3>
          <img src="@/assets/img/email.png" alt="" /><a
            href="mailto:getmoneyfit@ajg.com.au"
            >getmoneyfit@ajg.com.au</a
          ><br />
          <img src="@/assets/img/tel.png" alt="" class="mt-3" /><a
            href="tel:1300557782"
            >1300 557 782</a
          >
        </div>
        <div class="col-lg-4 form">
          <h3>Get in touch</h3>
          <div class="md-form">
            <input
              type="text"
              id="name"
              name="name"
              class="form-control"
              placeholder="Name"
              v-model="emailSender"
              required
            />
          </div>
          <div class="md-form">
            <input
              type="text"
              id="email"
              name="email"
              class="form-control"
              placeholder="Email"
              v-model="emailAddress"
              required
            />
          </div>
          <div class="md-form">
            <input
              type="tel"
              id="phone"
              name="phone"
              class="form-control"
              placeholder="Phone"
              v-model="emailTel"
              required
            />
          </div>
          <div class="md-form">
            <input
              type="text"
              id="employer"
              name="employer"
              class="form-control"
              placeholder="Employer"
              v-model="emailEmployer"
              required
            />
          </div>
        </div>
        <div class="col-lg-4 form">
          <div class="md-form">
            <select
              id="service"
              class="form-control"
              v-model="emailSubject"
              aria-label="Service"
              required
            >
              <option value="" disabled selected>Select service</option>
              <option value="Budgeting">Budgeting</option>
              <option value="Saving for the Future">
                Saving for the Future
              </option>
              <option value="Managing Debts">Managing Debts</option>
            </select>
          </div>
          <div class="md-form">
            <label for="message">Your message</label>
            <textarea
              class="form-control"
              id="message"
              rows="2"
              v-model="emailMessage"
              required
            ></textarea>
          </div>
          <button class="btn-get-started" @click="sendEmail" type="submit">
            CONTINUE
          </button>
        </div>
      </form>
    </section>
    <!-- End Contact Section -->
  </main>
  <!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer" ref="contacts" class="footer-top">
    <div class="overlay">
      <div class="container">
        <div class="row">
          <div class="col-12 footer-contact">
            <p>
              © Copyright Gallagher Benefit Services Pty Ltd. All Rights
              Reserved<br />
              <a target="_blank" href="https://www.ajg.com/au/about-us/"
                >Gallagher Benefit Services Pty Ltd</a
              >
              | ABN 49 611 343 803 | AFSL 488001
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-8 footer-contact">
            <p>
              <a target="_blank" href="https://www.ajg.com/au/privacy-policy/"
                >Privacy Policy | Legal and Regulatory Information</a
              >
            </p>
          </div>
          <div class="col-4 footer-contact">
            <p>Designed by Gallagher Communications</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- End Footer -->

  <a href="#" class="back-to-top" aria-label="Back to top"
    ><i class="ri-arrow-up-line"></i
  ></a>
  <!-- <div id="preloader"></div> -->
</template>
