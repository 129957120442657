import { defineStore } from "pinia";
import clientConfig from "../config/client";

export const useMoneyFitStore = defineStore("moneyfit", () => {
  // Inserts Microsoft Clarity tracking code
  function $insertClarityAnalyticsCode() {
    if (
      typeof clientConfig.analytics.enableClarity !== "undefined" &&
      clientConfig.analytics.enableClarity
    ) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.text =
        `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "` +
        clientConfig.analytics.clarityTrackingId +
        `");
      `;
      document.head.appendChild(script);
    }
  }

  return {
    $insertClarityAnalyticsCode,
  };
});
